<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

body {
  /* background-color: rgb(243, 243, 243); */

}

img {
  width: 100%;
  height: 100%;
  display: block;
}
a{
  text-decoration: none;
}
.el-pagination.is-background .el-pager li:hover {
  color: #fff !important;
  background-color: rgb(200, 22, 29) !important;
  /*hover时候的文字颜色*/
}

.el-pager li.active {
  color: #fff !important;
  background-color: rgb(200, 22, 29) !important;
  /*仅设置active时的文字颜色*/
}
.el-pagination{
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin: 50px auto;
}

.el-select-dropdown__item.selected {
    color: rgb(200, 22, 29);
}

/* 选中后的字体颜色 */
.el-radio__input.is-checked+.el-radio__label {
    color: rgb(200, 22, 29) !important;
}

/* 选中后小圆点的颜色 */
.el-radio__input.is-checked .el-radio__inner {
    background: rgb(200, 22, 29) !important;
    border-color: rgb(200, 22, 29) !important;
}
/* 选中后的字体颜色 */
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: rgb(200, 22, 29) !important;
}

/* 选中后小圆点的颜色 */
.el-checkbox__input.is-checked .el-checkbox__inner {
    background: rgb(200, 22, 29) !important;
    border-color: rgb(200, 22, 29) !important;
}
</style>
