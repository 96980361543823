<template>
    <div class="live-wrap" @click.prevent="goDetail">
        <!-- <router-link to="/yunduan"  active-class="active">About</router-link> -->
        <div class="live-list">
            <div class="live-img">
                <img :src="info.banner" alt="">
            </div>
            <div class="live-tip">
                <span class="live-class">
                    [{{ info.tag }}]
                </span>
                <span class="live-name">
                    {{ info.title }}
                </span>
            </div>
            <div class="live-bottom">
                <div class="lb-list">
                    <div class="lb-header">
                        <img :src="info.img" alt="">
                    </div>
                    <div class="lb-name">
                        {{info.name}}
                    </div>
                </div>
                <div class="lb-list">
                    <div class="lb-icon">
                        <img src="../assets/yunduan/xiaolian.png" alt="">
                    </div>
                    <div class="lb-name lb-right">
                        {{info.showNum}}
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

export default {
    name: "tabRowBg",
    data() {
        return {
            currentBox: ""
        }
    },
    props: {
        info: {
            type: Object,
            default: {}
        },
        current: {
            type: Number,
            default: 1
        },
    },
    mounted: function () {
        this.currentBox = this.current
    },
    created: function () {
        console.log(112,this.info)
    },
    methods: {
        goDetail() {
            switch (this.currentBox) {
                case 1:
                    this.$router.push("/ydDetail", () => { }, () => { })
                    break;
                case 2:
                    this.$router.push("/zbDetail", () => { }, () => { })
                    break;
                case 3:
                    this.$router.push("/zjDetail", () => { }, () => { })
                    break;
                case 4:
                    this.$router.push("/ydDetail", () => { }, () => { })
                    break;
                case 5:
                    this.$router.push("/ydDetail", () => { }, () => { })
                    break;
            }

        },
    }
}
</script>
<style scoped>
.live-wrap {
    width: 100%;
    height: 255px;
    position: relative;
}

.live-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 13px 0px rgba(35, 35, 35, 0.1);
    border-radius: 4px;
}

.live-img {
    width: 100%;
    height: 171px;
}

.live-tip {
    width: calc(100% - 20px);
    padding: 0 10px;
    height: 37px;
    line-height: 37px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    font-size: 14px;
    font-family: "Microsoft YaHei";
}

.live-class {
    color: rgb(200, 22, 29);
}

.live-name {
    color: rgb(86, 86, 86);
}

.live-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lb-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lb-header {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 10px;
}

.lb-name {
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: rgb(102, 102, 102);
    margin-left: 6px;
}

.lb-icon {
    width: 15px;
    height: 15px;
}

.lb-right {
    margin-right: 10px;
}
</style>
