<template>
    <div>
        <div class="currentTabWrap">
            <div @click="handleOneClick(e,i)" class="tabOne"
                :class="{'tabOnelist':currentTabIndex==i,'tabOnelist2':currentTabIndex!=i}" v-for="(e,i) in tabList"
                :key="i">
                <div class="tabOneText">{{e}}</div>
            </div>
            <div class="tab-sel-line"></div>
        </div>
    </div>
</template>
<script>

export default {
    name: "tabRowBg",
    data() {
        return {
            tabList: [],
            currentTabIndex: 0
        }
    },
    props: {
        tabRowList: {
            type: Array,
            default: []
        },
        tabLineIndex: {
            type: Number,
            default: 0
        }
    },
    mounted: function () {
        this.tabList = this.tabRowList
        this.currentTabIndex = this.tabLineIndex
    },
    created: function () {
        setTimeout(function () {
            var w = $(window).width()
            var n = 1920 / w
            var w1 = $(".currentTabWrap .tabOne").eq(0).width()
            w1 = w1 / n >= 24 ? w1 / n : 24
            $('.tab-sel-line').animate({
                width: w1 + "px"
            }, 0);
        }, 500)
    },
    methods: {
        handleOneClick(e, i) {
            var w = $(window).width()
            var n = 1920 / w
            this.currentTabIndex = i
            this.$emit('change', i)
            var w1 = $(".currentTabWrap .tabOne").eq(i).width()
            var left = 0
            if (i > 0) {
                for (var m = 0; m < i; m++) {
                    left += $(".currentTabWrap .tabOne").eq(m).width()
                    left += 24 / n
                }
            }
            $('.tab-sel-line').animate({
                left: (left + 12 / n) + "px",
                width: w1 + "px"
            }, 300);
        },
    }
}
</script>
<style scoped>
.currentTabWrap {
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
}

.tabOnelist {
    font-size: 14px;
    color: rgb(200, 22, 29);
    font-family: "Microsoft YaHei";
    line-height: 50px;
    padding: 0px 12px;
}

.tabOnelist2 {
    font-size: 14px;
    color: rgb(86, 86, 86);
    font-family: "Microsoft YaHei";
    line-height: 50px;
    padding: 0px 12px;
}

.tab-sel-line {
    position: absolute;
    left: 12px;
    width: 2px;
    background-color: rgb(200, 22, 29);
    bottom: 2px;
    height: 4px;
    border-radius: 2px;
}
</style>
