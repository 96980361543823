<template>
    <div class="live-wrap">
        <div class="t-l-b-top">
            <div class="t-l-b-top-img">
                <img src="../assets/yunduan/demo.png" alt="">
            </div>
            <div class="t-l-b-top-content">
                <div class="t-l-b-top-name">
                    <span class="t-l-b-top-status">
                        [职业教育]
                    </span>
                    <span>
                        金融人才成长计划金融人才成长计划金融人才成长计划金融人才成长计划
                    </span>
                </div>
                <div class="t-l-b-top-person">
                    主讲人：张老师
                </div>
            </div>
        </div>
        <div class="t-l-b-tip">
            要描述简要描述简要描述简要描述要描述简要描述简要描述简要描述要描述简要描述简要描述简要描述要描述简要描述简要描述简要描述要描述简要描述简要描述简要描述
        </div>
    </div>
</template>
<script>

export default {
    name: "vedioLine",
    data() {
        return {
        }
    },
    props: {

    },
    mounted: function () {

    },
    created: function () {

    },
    methods: {

    }
}
</script>
<style scoped>
.live-wrap {
    width: 100%;
    height: 169px;
    position: relative;
}

.t-l-b-top {
    width: 362px;
    height: 74px;
    display: flex;
    flex-direction: row;
}

.t-l-b-top-img {
    width: 126px;
    height: 74px;
    /* border: 1px solid #f00; */
}

.t-l-b-top-content {
    width: 216px;
    margin-left: 20px;
    line-height: 25px;
}

.t-l-b-top-name {
    height: 50px;
    font-size: 14px;
    color: rgb(102, 102, 102);
    font-family: "Microsoft YaHei";
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.t-l-b-top-status {
    color: rgb(200, 22, 29);
}

.t-l-b-top-person {
    font-size: 14px;
    color: rgb(102, 102, 102);
    font-family: "Microsoft YaHei";
}

.t-l-b-tip {
    font-size: 12px;
    line-height: 18px;
    font-family: "Microsoft YaHei";
    color: rgb(153, 153, 153);
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-indent: 24px;
    margin-top: 18px;
}
</style>
