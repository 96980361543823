
import axios from 'axios'
// import qs from 'qs'
// api 模块化
import apis from './modules'
// axios.defaults.baseURL = "http://localhost:8081/#/"
// developmentURL  此处请填写您的域名
const developmentURL = "http://localhost:8081/#/"
axios.defaults.timeout = 300000
axios.defaults.baseURL = developmentURL
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}, type = 'application/json') {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            headers: {
                'Content-Type': type
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, type = 'application/json') {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'Content-Type': type
            }
        }).then(response => {
            resolve(response.data)
        }, err => {
            reject(err)
        })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}, type = 'application/json') {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}, type = 'application/json') {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装del请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, params = {}, type = 'application/json') {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: params,
            headers: {
                'Content-Type': type
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装post上传图片、视频、音频、文件的接口
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postUpload(url, data = {}, progressCallback) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            },
            onUploadProgress: progressCallback
        }) // qs.stringify(data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * @method 获取七牛token
 * @param url
 * @param data
 * @returns {Promise}
 */
export function getQiniuToken(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 获取数据的接口
 */
export const api = {
    ...apis
}

