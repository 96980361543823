<template>
    <div>
        <div class="topNavWrap">
            <div class="topNav-box">
                <div class="top-b-left">
                    <div class="top-b-logo">
                        <img src="../assets/topNav/deng.png" alt="">
                    </div>
                    <div class="top-b-text">
                        <img src="../assets/topNav/text.png" alt="">
                    </div>
                </div>
                <div class="top-b-middle">
                    <div :class="{'t-b-m-list':currentIndex==i,'t-b-m-list1':currentIndex!=i}" v-for="(e,i) in navList"
                        :key="i" @click="clickTop(e,i)">
                        {{e.name}}
                    </div>
                </div>
                <div class="top-b-right">
                    <div class="top-search-box">
                        <div class="top-search-inner">
                            <input class="top-search-input" v-model="contentInput" type="text" placeholder="搜索课程、专家、直播">
                        </div>
                        <div class="top-swiper-icon2">
                            <img src="../assets/topNav/search.png" alt="">
                        </div>
                    </div>
                    <div class="s-person">
                        <img src="../assets/topNav/person.png" alt="">
                    </div>
                    <div v-if="showInfo" class="top-b-right">
                        <div class="s-text" @click="gologin">
                            {{adminInfo.admin}}
                        </div>
                    </div>
                    <div v-else class="top-b-right">
                        <div class="s-text" style="color:rgb(226, 41, 47)" @click="gologin">
                            登录
                        </div>
                        <div class="s-text">
                            /
                        </div>
                        <div class="s-text" @click="goregist">
                            注册
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="topHeight"></div> -->
    </div>
</template>
<script>

export default {
    name: "topNav",
    data() {
        return {
            showInfo: false,
            adminInfo: "",
            currentIndex: 0,
            contentInput: "",
            navList: [{
                name: "首页",
                url: "/index"
            }, {
                name: "云端讲堂",
                url: "/yunduan"
            }, {
                name: "直播开讲",
                url: "/zhibo"
            }, {
                name: "专家名师",
                url: "/zhuanjia"
            }, {
                name: "咨询互动",
                url: "/zixun"
            }, {
                name: "思想调查",
                url: "/sixiang"
            }, {
                name: "会员中心",
                url: "/vip"
            }]
        }
    },
    props: {
        current: {
            type: Number,
            default: "0"
        }
    },
    mounted: function () {
        $('.topNavWrap').addClass('animated fadeInDown')
        var info = window.localStorage.getItem("myjjForm")
        if (info) {
            this.showInfo = true
            this.adminInfo = JSON.parse(info)
        } else {
            this.showInfo = false
        }
    },
    created: function () {
        this.currentIndex = this.current
    },
    methods: {
        clickTop(e, i) {
            this.currentIndex = i
            this.$router.replace(e.url, () => { }, () => { })
        },
        gologin() {
            this.$router.push("/login", () => { }, () => { })
        },
        goregist() {
            this.$router.push("/regist", () => { }, () => { })
        },
    }
}
</script>
<style scoped>
.topNavWrap {
    width: 100%;
    height: 118px;
    overflow: hidden;
    /* position: fixed;
    left: 0;
    top: 0; */
    z-index: 9999;
    background-color: #fff;
}

.topHeight {
    width: 100%;
    height: 118px;

}

.topNav-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-b-left,
.top-b-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top-b-logo {
    width: 46px;
    height: 53px;
}

.top-b-text {
    width: 208px;
    height: 64px;
}

.top-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    background-color: #d5d5d5;
    padding: 0px 12px;
    border-radius: 15px;
    background-color: #e8e8e8;
}

.top-search-inner {
    width: 140px;
    height: 30px;
    margin-right: 12px;
    position: relative;
}

.top-search-input {
    position: absolute;
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: rgb(51, 51, 51);
    background: none;
    border: none;
    border: none;
    outline: 0;
}


.top-search-input::-webkit-input-placeholder {
    -webkit-text-fill-color: #ccc;
}

.top-search-input::-moz-input-placeholder {
    -webkit-text-fill-color: #ccc;
}

.top-search-input::-ms-input-placeholder {
    -webkit-text-fill-color: #ccc;
}

.top-swiper-icon2 {
    width: 14px;
    height: 14px;
}

.s-person {
    width: 30px;
    height: 30px;
    background-color: #9C9C9C;
    border-radius: 50%;
    margin-left: 4px;
}

.s-text {
    padding: 0px 4px;
    font-size: 13px;
    font-family: "Microsoft YaHei";
    color: #333;
}

.top-b-middle {
    width: 560px;
    display: flex;
    justify-content: space-around;
}

.t-b-m-list {
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: rgb(226, 41, 47)
}

.t-b-m-list1 {
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: rgb(51, 51, 51);
}
</style>
