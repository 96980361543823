import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const constantRoutes = [
    {
        path: '/',
        redirect: 'index',//重定向
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/yindaoPage/index.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
    },
    {
        path: '/regist',
        name: 'regist',
        component: () => import('@/views/regist.vue'),
    },
    {
        path: '/sixiang',
        name: 'sixiang',
        component: () => import('@/views/sixiangPage/index.vue')
    },
    {
        path: '/sxDetail',
        name: 'sxDetail',
        component: () => import('@/views/sixiangPage/detail.vue')
    },
    {
        path: '/vip',
        name: 'vip',
        redirect: 'ziliao',//重定向
        component: () => import('@/views/vipPage/index.vue'),
        children: [{
            path: '/ziliao',   //个人资料
            name: 'ziliao',
            component: () => import('@/views/vipPage/ziliao.vue'),
        }, {
            path: '/lingyu',   //爱好领域
            name: 'lingyu',
            component: () => import('@/views/vipPage/lingyu.vue'),
        }, {
            path: '/lishi',   //阅读历史
            name: 'lishi',
            component: () => import('@/views/vipPage/lishi.vue'),
        }, {
            path: '/soucang',   //我的收藏
            name: 'soucang',
            component: () => import('@/views/vipPage/soucang.vue'),
        }, {
            path: '/pinglun',   //我的评论
            name: 'pinglun',
            component: () => import('@/views/vipPage/pinglun.vue'),
        }, {
            path: '/guanzhu',   //我的关注
            name: 'guanzhu',
            component: () => import('@/views/vipPage/guanzhu.vue'),
        }, {
            path: '/chuangzuo',   //我的创作
            name: 'chuangzuo',
            redirect: '/chuangzuo/chuangzuoIndex',//重定向
            component: () => import('@/views/vipPage/chuangzuo.vue'),
            children: [{
                path: '/chuangzuo/chuangzuoIndex',   //我的创作
                name: 'chuangzuoIndex',
                component: () => import('@/views/vipPage/chuangzuoIndex.vue')
            }, {
                path: '/chuangzuoEdit',   //我的创作编辑
                name: 'chuangzuoEdit',
                component: () => import('@/views/vipPage/chuangzuoEdit.vue'),
            }]
        }]
    },
    {
        path: '/yunduan',
        name: 'yunduan',
        component: () => import('@/views/yunduanPage/index.vue'),
    },
    {
        path: '/ydDetail',
        name: 'ydDetail',
        component: () => import('@/views/yunduanPage/detail.vue'),
    },
    {
        path: '/ydStudy',
        name: 'ydStudy',
        component: () => import('@/views/yunduanPage/study.vue'),
    },
    {
        path: '/zhibo',
        name: 'zhibo',
        component: () => import('@/views/zhiboPage/index.vue')
    },
    {
        path: '/zbDetail',
        name: 'zbDetail',
        component: () => import('@/views/zhiboPage/detail.vue')
    },
    {
        path: '/zhuanjia',
        name: 'zhuanjia',
        component: () => import('@/views/zhuanjiaPage/index.vue')
    },
    {
        path: '/zjDetail',
        name: 'zjDetail',
        component: () => import('@/views/zhuanjiaPage/detail.vue')
    },
    {
        path: '/zixun',
        name: 'zixun',
        component: () => import('@/views/zixunPage/index.vue')
    },
    {
        path: '/zxDetail',
        name: 'zxDetail',
        component: () => import('@/views/zixunPage/detail.vue')
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    },

]


const router = new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})



// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export default router
