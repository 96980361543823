import Vue from 'vue'
import App from './App.vue'
// import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import './config/rem'  //px转rem
import 'element-ui/lib/theme-chalk/index.css';
import *as animated from 'animate.css'
import topNav from '@/components/topNav.vue'
Vue.component('topNav', topNav)
import topBanner from '@/components/topBanner.vue'
Vue.component('topBanner', topBanner)
import tabRowBg from '@/components/tabRowBg.vue'
Vue.component('tabRowBg', tabRowBg)
import tabRowLine from '@/components/tabRowLine.vue'
Vue.component('tabRowLine', tabRowLine)
import vedioBox from '@/components/vedioBox.vue'
Vue.component('vedioBox', vedioBox)
import vedioLine from '@/components/vedioLine.vue'
Vue.component('vedioLine', vedioLine)
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)

import { api } from './api/index.js' // 接口
Vue.prototype.$api = api

// import $ from 'jquery'
Vue.use(animated)
Vue.use(ElementUI);
// Vue.use($)
Vue.config.productionTip = false  //阻止你显示显示生产模式的消息

new Vue({
  router,
  // store,
  render: h => h(App),      //h相当于创建元素   es6语法
}).$mount('#app')
