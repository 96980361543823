<template>
    <div class="st-tabwrap">
        <div class="tabwrap">
            <div @click="handleClick(e,i)" :class="{'tablist':currentIndex==i,'tablist2':currentIndex!=i}"
                v-for="(e,i) in tablist" :key="i">
                {{e}}
            </div>
            <div class="tab-sel"></div>
        </div>
    </div>
</template>
<script>

export default {
    name: "tabRowBg",
    data() {
        return {
            tablist: [],
            currentIndex: 0
        }
    },
    props: {
        tabRowList: {
            type: Array,
            default: []
        },
        tabRowIndex: {
            type: Number,
            default: 0
        }
    },
    mounted: function () {
        this.tablist = this.tabRowList
        this.currentIndex = this.tabRowIndex
        var i = this.currentIndex
        var w = $(window).width()
        var n = 1920 / w
        $('.tab-sel').animate({
            left: 175 / n * i + "px"
        }, 500);
    },
    created: function () {

    },
    methods: {
        handleClick(e, i) {
            var w = $(window).width()
            var n = 1920 / w
            this.currentIndex = i
            this.$emit('change', i)
            $('.tab-sel').animate({
                left: 175 / n * i + "px"
            }, 500);
            setTimeout(function () {
                $('.tablist').addClass('animated bounceIn')
            }, 500)
        },
    }
}
</script>
<style scoped>
.st-tabwrap {
    position: sticky;
    top: 0;
    z-index:999;
}

.tabwrap {
    width: 100%;
    height: 50px;
    background-color: rgb(243, 243, 243);
    border-bottom: 1px solid rgb(228, 228, 228);
    position: relative;
    display: flex;
    flex-direction: row;

}

.tablist {
    width: 175px;
    height: 50px;
    color: #fff;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    z-index: 1;
}

.tablist2 {
    width: 175px;
    height: 50px;
    font-size: 20px;
    color: rgb(86, 86, 86);
    font-family: "Microsoft YaHei";
    line-height: 50px;
    text-align: center;
    z-index: 1;
}

.tab-sel {
    width: 175px;
    height: 50px;
    background-color: rgb(200, 22, 29);
    position: absolute;
    left: 0;
    top: 0;
}
</style>
