<template>
    <div class="swiper-wrap">
        <el-carousel indicator-position="none" :interval="2500" :height="bannerHeight" :loop="true" :autoplay="true">
            <el-carousel-item v-for="item in imgList" :key="item">
                <div class="swiper-medium">
                    <img :src="item" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>

export default {
    name: "topBanner",
    data() {
        return {
            imgList: [require('../assets/img/1.jpg'), require('../assets/img/2.jpg'), require('../assets/img/3.jpg'), require('../assets/img/4.jpg'), require('../assets/img/5.jpg'), require('../assets/img/6.jpg')],
            bannerHeight: "620px"
        }
    },
    props: {

    },
    mounted: function () {
        var w = $(window).width()
        this.bannerHeight = 620 * w / 1920 + "px"
    },
    created: function () {
      
    },
    methods: {

    }
}
</script>

<style scoped>
.swiper-wrap {
    width: 100%;
}
</style>
